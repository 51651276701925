<template>
  <div class="box">
    <flv-player class="room-Player" ref="playerLive" :configuration="configuration"></flv-player>

    <div class="box-button">
      <a-button type="primary" @click="earlyWarning">预警</a-button>
      <a-button type="primary" @click="reconnectionButton">重连</a-button>
      <a-button type="primary" @click="goBack">返回</a-button>
    </div>
  </div>
  
  <revision-modal :title="modal.title" :class="[modal.earlyWarning ? 'earlyWarning-box' : '']">
    <template v-slot:content>
      <div v-html="modal.content"></div>
      <!-- 发起预警 -->
      <div v-if="modal.earlyWarning">
        <div class="itemBox">
          <span>违法类型：</span>
          <a-select
            v-model:value="earlyWarningObj.illegalType"
            allowClear
            class="itemRight"
            placeholder="请选择违法类型"
          >
            <a-select-option :value="item.id" v-for="(item, i) in TypesViolationList" :key="i">{{
              item.name
            }}</a-select-option>
          </a-select>
        </div>
        <div class="itemBox">
          <span>案件描述：</span>
          <a-textarea
            class="itemRight"
            v-model:value="earlyWarningObj.description"
            placeholder="请输入案件描述"
            :rows="4"
          />
        </div>
        <div class="itemBox">
          <span>证据图片：</span>
          <div class="itemRight" @click="amplification">
            <img :src="earlyWarningObj.evidencePicture" />
          </div>
        </div>
        <div class="itemBox">
          <span>工单派发：</span>
          <a-select
            v-model:value="earlyWarningObj.platform"
            @change="handleChange"
            allowClear
            class="itemRight"
            placeholder="请选择工单派发系统"
          >
            <a-select-option :value="1">综合执法平台</a-select-option>
            <a-select-option :value="2">智慧城管平台</a-select-option>
            <a-select-option :value="3">非接触性执法平台</a-select-option>
          </a-select>
        </div>
        <div class="itemBox" v-show="departmencode_show">
          <span>执法大队：</span>
          <a-select
            v-model:value="earlyWarningObj.departmencode"
            allowClear
            class="itemRight"
            placeholder="请选择执法大队"
          >
            <a-select-option value="直属执法大队">直属执法大队</a-select-option>
            <a-select-option value="拙政园片区综合新政执法大队">拙政园片区综合新政执法大队</a-select-option>
            <a-select-option value="阊门片区新政执法大队">阊门片区新政执法大队</a-select-option>
            <a-select-option value="盘门片区新政执法大队">盘门片区新政执法大队</a-select-option>
            <a-select-option value="葑门片区新政执法大队">葑门片区新政执法大队</a-select-option>
            <a-select-option value="山塘片区新政执法大队">山塘片区新政执法大队</a-select-option>
            <a-select-option value="平江新城综合行政执法大队">平江新城综合行政执法大队</a-select-option>
            <a-select-option value="沧浪新城综合行政执法大队">沧浪新城综合行政执法大队</a-select-option>
            <a-select-option value="金阊新城综合行政执法大队">金阊新城综合行政执法大队</a-select-option>
            <a-select-option value="火车站大队">火车站大队</a-select-option>
          </a-select>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <a-button type="primary" v-show="modal.earlyWarning" @click="agent" class="button-modal">代办</a-button>
      <a-button type="primary" @click="ok" class="button-modal">推送</a-button>
    </template>
  </revision-modal>

  <a-drawer title="画框" placement="right" :closable="false" v-model:visible="modifyImage" width="80%">
    <div id="modifyImage">
      <div id="box-canvas">
        <canvas
          id="canvas"
          class="canvas"
          @mousedown="mouseDown($event)"
          @mouseup="mouseUp($event)"
          @mousemove="mouseMove($event)"
        ></canvas>
      </div>
    </div>
    <a-button type="primary" @click="() => (modifyImage = false)">下一步</a-button>
  </a-drawer>
</template>

<script>
import {  nextTick, ref, provide } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import FlvPlayer from '@/components/FlvPlayer.vue';
import { message } from 'ant-design-vue';
import api from '@/services';
import { FormatTime } from '@/utils/tool.js';
import RevisionModal from '@/components/RevisionModal.vue';
export default {
  components: {
    FlvPlayer,
    RevisionModal,
  },
  setup() {
    const playerLive = ref(null);
    const route = useRoute();
    const router = useRouter();
    const configuration = ref(null);
    // 弹框的显示和隐藏
    const modal = ref({
      title: '',
      content: ''
    });
   
   const visible = ref(false);
    provide('visible', visible);
    // 发起预警
    const earlyWarningObj = ref({
      // 描述
      description: '',
      // 图片
      evidencePicture: '',
      // 非法类型
      illegalType: null,
      // 画框坐标
      top: null,
      left: null,
      width: null,
      height: null,
      // 列表选择
      platform: null,
      // 直播间ID
      roomId: route.query.id,

      departmencode: null,

      startTime: ''
    });
    function getShot(video, x, y, width, height) {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      console.log(video, x, y, width, height);
      ctx.drawImage(video, x, y, width, height);
      console.log(canvas.toDataURL())
      return canvas.toDataURL();
    }
    if (route.query?.pullFlow) {
      configuration.value = {
        url: route.query?.pullFlow,
      };
    }
    // 违法类型
    const TypesViolationList = ref([]);
    const getTypesViolation = async () => {
      const { data } = await api.getTypesViolation();
      TypesViolationList.value = data;
    };
    // 预警
    const earlyWarning = () => {
      getTypesViolation();
      modal.value = {
        earlyWarning: true,
      };
      visible.value = true;
      nextTick(() => {
        let dom = document.getElementsByClassName('room-Player')[0];
        earlyWarningObj.value.evidencePicture = getShot(dom, 0, 0, dom.offsetWidth, dom.offsetHeight);
      });
    };
    const modifyImage = ref(false);
    const isflag = ref(false);
    let startX,
      startY,
      ctx,
      isdown = 0,
      canvas,
      imageUrl;
    const amplification = () => {
      modifyImage.value = true;
      if (isflag.value) {
        message.info('画框请重新截图');
        return false;
      }
      isflag.value = true;
      nextTick(() => {
        canvas = document.getElementById('canvas');
        let img = new Image();
        img.src = earlyWarningObj.value.evidencePicture;
        let context = canvas.getContext('2d');
        ctx = context;
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          img.classList.add('box-canvas-img');
          document.getElementById('box-canvas').appendChild(img);
        };
      });
    };
    // 矩型
    const drawIs = ref(false);
    function drawRect(e) {
      if (isdown) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.lineWidth = '3';
        ctx.strokeStyle = '#FF0000';
        ctx.strokeRect(startX, startY, e.offsetX - startX, e.offsetY - startY);

        earlyWarningObj.value.left = startX;
        earlyWarningObj.value.top = startY;
        earlyWarningObj.value.width = e.offsetX - startX;
        earlyWarningObj.value.height = e.offsetY - startY;
      }
    }
    const mouseDown = e => {
      isdown = 1;
      drawIs.value = false;
      startX = e.offsetX; // 鼠标落下时的X
      startY = e.offsetY; // 鼠标落下时的Y
    };
    const mouseUp = e => {
      isdown = 0;
      drawIs.value = true;
    };
    const mouseMove = e => {
      if (drawIs.value) {
        return false;
      }
      drawRect(e);
    };

    const reconnectionButton = () => {
      message.info('正在尝试重新连接，请稍后');
      playerLive.value.reconnection();
    };

    const okObj = {
      earlyWarning: async () => {
        let isGo = true;
        earlyWarningObj.value.startTime = FormatTime(new Date(), 'yyyy-MM-dd hh:mm:ss');
        Object.keys(earlyWarningObj.value).forEach(valueKey => {
          if (!earlyWarningObj.value[valueKey]) {
            if (valueKey == 'departmencode') {
            } else if (valueKey == 'top' || valueKey == 'left' || valueKey == 'height' || valueKey == 'width') {
              message.error('请画框');
            } else {
              message.error('请填写完整');
              isGo = false;
            }
          }
        });
        if (!isGo) {
          return false;
        }
        try {
          const { success } = await api.earlyWarning(earlyWarningObj.value);
          if (success) message.success('预警成功');
          earlyWarningObj.value = {
            // 描述
            description: '',
            // 图片
            evidencePicture: '',
            // 非法类型
            illegalType: null,
            // 画框坐标
            top: null,
            left: null,
            height: null,
            width: null,
            // 列表选择
            platform: null,
            // 直播间ID
            roomId: route.query.id,

            departmencode: null,
          };
        } catch (error) {
          message.error('网络错误');
        }
        handleCancel();
      },
    };
    const ok = () => {
      okObj[Object.keys(modal.value).pop()]();
    };

    // 弹窗取消
    const handleCancel = async () => {
      visible.value = false;
    };

    const departmencode_show = ref(false);
    const handleChange = value => {
      if (value == 3) {
        departmencode_show.value = true;
      } else {
        departmencode_show.value = false;
      }
    };
    const keyupEnter = () => {
      document.onkeydown = e => {
        if (e.keyCode === 69 && e.target.baseURI.match(/live-broadcast-enlarge/)) {
          agent()
          // earlyWarning();
        }
      };
    };

    nextTick(() => {
      keyupEnter();
    });

    const goBack = () => {
      router.go(-1);
    }

    const agent =  () => {
      nextTick( async () => {
        let dom = document.getElementsByClassName('room-Player')[0];
        earlyWarningObj.value.evidencePicture = getShot(dom, 0, 0, dom.offsetWidth, dom.offsetHeight);

        earlyWarningObj.value.startTime = FormatTime(new Date(), 'yyyy-MM-dd hh:mm:ss');
        let params = {
          ...earlyWarningObj.value
        }
        Object.keys(params).forEach((item) => {
          const key = params[item];
          if (key === "" || key === null || key === undefined) {
            delete params[item];
          }
        });
        const data = await api.agent(params);
        message.success('代办成功');
      });
    }
    return {
      configuration,
      earlyWarning,
      reconnectionButton,
      playerLive,
      mouseDown,
      mouseUp,
      mouseMove,
      ok,
      handleCancel,
      modifyImage,
      amplification,
      departmencode_show,
      handleChange,
      modal,
      earlyWarningObj,
      TypesViolationList,
      goBack
    };
  },
};
</script>

<style lang="less" scoped>
.box {
  position: relative;
  .room-Player {
    width: 100vw;
    height: 100vh;
  }
  &-button {
    position: absolute;
    top: 40%;
    display: flex;
    flex-direction: column;
    > button {
      width: 90px;
      height: 40px;
      font-size: 16px;
      color: #0079ed;
      background-color: #fff;
      border-color: #fff;
      padding: 0 10px 0 5px;
      margin-bottom: 30px;
      border-radius: 0px 20px 20px 0px;
    }
  }
}
.earlyWarning-box {
  ::v-deep(.captcha-modal) {
    .ant-modal-content {
      padding: 0;
      .ant-modal-body {
        background-color: #fff;
        padding: 56px 40px 36px;
      }
      .itemBox {
        display: flex;
        span {
          flex: 1;
          display: block;
          color: #666666;
          font-size: 14px;
        }

        .itemRight {
          flex: 5;
        }
        textarea {
          padding: 0 !important;
          border-radius: 5px;
          height: 150px;
          resize: none;
        }
        textarea::placeholder {
          padding: 2px;
        }
        .ant-select-selector {
          border-radius: 5px;
        }
      }
      .itemBox:nth-child(1) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .itemBox:nth-child(2) {
        margin: 20px 0;
      }
      .itemBox:nth-child(3) {
        div {
          height: 144px;
          border: 1px solid #d8d8d8;
          border-radius: 5px;
        }
      }
      .itemBox:nth-child(4) {
        margin: 20px 0;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .itemBox:nth-child(5) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
#box-canvas {
  margin-bottom: 10px;
  position: relative;
}
::v-deep .box-canvas-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: auto;
  height: auto;
}

.reload-db {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.canvas {
  border: 1px solid #d3d3d3;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>
